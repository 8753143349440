/* eslint-disable no-console */
(function() {
    'use strict';

    angular.module('vobeApp').controller('PropertyVouchersBulkAddController', PropertyVouchersBulkAddController);

    function PropertyVouchersBulkAddController(
        $window,
        $document,
        $scope,
        $rootScope,
        $state,
        $transition$,
        previousState,
        $location,
        DetermineItemLanguage,
        DetermineShopMoreState,
        $sce,
        voucherPool,
        shoppingCart,
        shoppingCartConfigs,
        currentProperty,
        currentPropertyGroup,
        currentLanguage,
        ShoppingCartAddBulkRecipientsAndItem,
        ShoppingCartItemDetailsService,
        $q,
        $injector,
        $localStorage,
        PromotionCodeService,
        $filter,
        $locale,
        $translate
    ) {
        'ngInject';
        console.log('property vouchers bulk add controller');
        //NOTE: 'hot-table' directive is using lazy loaded modules, see html file
        //https://oclazyload.readme.io/docs/oclazyload-directive
        var vm = this;

        var locale = $window.navigator.language || $window.navigator.userLanguage;
        var decimalSeparator = Intl && Intl.NumberFormat ? Intl.NumberFormat(locale).format('1.1').charAt(1) : '.';

        vm.voucherPool = voucherPool;
        console.log('bulkAdd voucherPool: ', voucherPool)
        vm.shoppingCartConfigs = shoppingCartConfigs;


        vm.currentProperty = currentProperty;
        vm.currentPropertyGroup = currentPropertyGroup;
        vm.currentLanguage = currentLanguage;

        console.log('sshoppingCartConfigs', vm.shoppingCartConfigs[vm.currentProperty.hotelId].configMap);

        vm.property = currentProperty;//this variable is needed for shopping summary included in the page
        vm.shoppingCart = shoppingCart;//this variable is needed for shopping summary included in the page
        vm.forceHidePromoBox = true;//we don't need promo box here

        vm.appliedPromotionCode = $transition$.params().appliedPromotionCode;
        vm.currentVoucher = ShoppingCartItemDetailsService.getDefaultVoucher($transition$.params().focusedAmount, $transition$.params().voucherId, vm.voucherPool); //this will populate vm.currentVoucher

        vm.voucherProperty = (typeof vm.currentPropertyGroup === 'undefined') ? ShoppingCartItemDetailsService.getVoucherPropertyForIndividualSite(vm.currentVoucher,vm.currentProperty) : ShoppingCartItemDetailsService.getVoucherPropertyForGroupSite(vm.currentVoucher, vm.currentPropertyGroup);

        vm.bulkShoppingCartConfigs = angular.extend({}, vm.shoppingCartConfigs);
        vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.disableRecipientMessage = 'true';
        vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.disableRecipientDetails = 'true';
        vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.disableDeliverToCustomer = 'true';
        vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.postalOption = 'false';

        //override maxBulkRows if defined in voucher property config
        console.log('------------vm.currentProperty.hotelId', vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap)
        vm.maxBulkRows = vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.maxBulkRows || (vm.isRefundsContext ? 30 : 30);
        vm.allowBulkPostal = vm.bulkShoppingCartConfigs[vm.currentProperty.hotelId].configMap.allowBulkPostal || false;

        //check itemMeta.emailOption, itemMeta.postalOption, itemMeta.collectOption for 'true' or 'false'
        // if in that order they are true, set them as default delivery method type via "email", "post" or "collect"
        if (vm.currentVoucher.meta) {
            if (vm.currentVoucher.meta.emailOption === 'true' && vm.currentVoucher.meta.postalOption === 'false' && vm.currentVoucher.meta.collectOption === 'false') {
                vm.currentVoucher.meta.defaultDeliveryMethodType = 'email';
            } else if (vm.currentVoucher.meta.emailOption === 'false' && vm.currentVoucher.meta.postalOption === 'true' && vm.currentVoucher.meta.collectOption === 'false') {
                vm.currentVoucher.meta.defaultDeliveryMethodType = 'post';
            } else if (vm.currentVoucher.meta.emailOption === 'false' && vm.currentVoucher.meta.postalOption === 'false' && vm.currentVoucher.meta.collectOption === 'true') {
                vm.currentVoucher.meta.defaultDeliveryMethodType = 'collect';
            }
        }

        vm.templateBulkItem = {

            //uuid is needed for the form
            uuid: 'bulk',
            //item specification
            itemId: vm.currentVoucher.id,
            itemName: vm.currentVoucher.voucherName,
            itemDescription: vm.currentVoucher.voucherDescription,
            collectionDescription: vm.currentVoucher.collectDescription,
            //this is the value of the voucher, it will be used to recalculate the 'itemTotalPrice', do not remove it
            //if you want to remove it, then 'itemTotalPrice' must be changed to contain the value without discounts
            itemTotalPriceBeforeDiscount: (vm.currentVoucher.selectedAmount > 0 ? vm.currentVoucher.selectedAmount : vm.currentVoucher.totalRate.priceMin),
            itemTotalPrice: ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount((vm.currentVoucher.selectedAmount > 0 ? vm.currentVoucher.selectedAmount : vm.currentVoucher.totalRate.priceMin),vm.appliedPromotionCode, 1), //it will be recalculated serverside
            itemCurrency: vm.currentVoucher.currency,
            itemImageFileName: ShoppingCartItemDetailsService.getImageFileName(vm.voucherPool),
            itemType: vm.voucherPool.type,
            itemGiftCardId: vm.voucherPool.giftCardId,
            itemEmailCategoryId: !vm.currentVoucher.meta ? 1 : vm.currentVoucher.meta.selectedEmailCategoryId,
            itemFixedDeliveryType: !vm.currentVoucher.meta ? null : vm.currentVoucher.meta.fixedDeliveryType,
            itemMeta: !vm.currentVoucher.meta ? null : vm.currentVoucher.meta,
            owningProperty : {
                propertyId : vm.voucherProperty.hotelId,
                propertyCode : vm.voucherProperty.hotelCode
            },
            itemQuantity : undefined,
            recipient : {
                deliveryMethodType: vm.currentVoucher.meta.defaultDeliveryMethodType,
                deliveryPersonType: 'recipient',
                showValue: vm.voucherPool.type=='cash' || vm.voucherPool.type=='cash-wildcard' ? 'true' : ShoppingCartItemDetailsService.defaultShowValue($transition$.params().contextType=='refunds'?'refunds':vm.voucherPool.type,vm.voucherProperty)
            },
            meta: vm.currentVoucher.meta,
            additionalFields : {
                'bulk' : true
            }
        };
        vm.templateBulkItem.itemLanguage = DetermineItemLanguage.getLanguage(vm.templateBulkItem);

        //build additional fields
        if(vm.currentVoucher.meta && vm.currentVoucher.meta.redemptionEndDate && vm.currentVoucher.meta.redemptionEndDate!=null ){
            vm.templateBulkItem.additionalFields['not_valid_after_date'] = new Date(vm.currentVoucher.meta.redemptionEndDate).getTime()/1000;
        }

        vm.totalPrice = vm.templateBulkItem.itemTotalPrice;
        vm.totalValue = vm.templateBulkItem.itemTotalPriceBeforeDiscount;
        /**
         * Create values to use in the dropdown
         */
        vm.valueRange = [];
        if(vm.voucherPool.type.includes('package') || vm.voucherPool.type.includes('cash-wildcard')){
            if (vm.currentVoucher.totalRate.increment > 0) {
                //if it's a package and has increments create array of numbers to display as value
                vm.valueRange = range(vm.voucherPool.minValue, vm.voucherPool.maxValue, vm.currentVoucher.totalRate.increment);
            }
            else {
                vm.valueRange.push(parseNumber(vm.currentVoucher.totalRate.priceMin));
            }
        } else {
            angular.forEach(vm.voucherPool.vouchers,function (voucher,vkey) {
                vm.valueRange.push(parseNumber(voucher.totalRate.priceMin));
            });
        }

        vm.valueRange.sort(function(v1, v2) {
            return v1 - v2;
        });

        vm.browseMore = browseMore;
        vm.goToPayment = goToPayment;

        //if this is refunds, and dedication not forced, don't use it
        vm.useDedication = $transition$.params().contextType=='refunds' && ($transition$.params().dedication!='true') ? false : true;
        //if this is refunds, and comment not forcefully disabled, use it
        vm.useComment = $transition$.params().contextType=='refunds' && ($transition$.params().comment!='false') ? true : false;

        function addBulkRecipientsAndItem (callback, errorCallback) {
            var cb = callback || angular.noop;
            var ecb = errorCallback || angular.noop;

            var bulkVM = {
                item : {
                    propertyId: vm.voucherProperty.hotelId,
                    propertyCode: vm.voucherProperty.hotelCode,
                    propertyName: vm.voucherProperty.hotelName,
                    propertyCountry: vm.voucherProperty.hotelCountry,
                    itemId: vm.templateBulkItem.itemId,
                    itemName: vm.templateBulkItem.itemName,
                    itemDescription: vm.templateBulkItem.itemDescription,
                    itemTotalPrice: vm.templateBulkItem.itemTotalPriceBeforeDiscount,
                    itemCurrency: vm.templateBulkItem.itemCurrency,
                    itemType: vm.templateBulkItem.itemType,
                    itemEmailCategoryId: vm.templateBulkItem.itemEmailCategoryId,
                    itemFixedDeliveryType: vm.templateBulkItem.itemFixedDeliveryType,
                    itemImageFileName: vm.templateBulkItem.itemImageFileName,
                    itemLanguage: vm.templateBulkItem.itemLanguage,
                    passkey: $transition$.params().passkey || '',
                    channelId: $transition$.params().channel || 0
                },
                recipients: []
            };

            angular.forEach(vm.hot.items, function(bulkRecipientModel, key) {

                var bulkRecipient = bulkRecipientModel.internalData;
                var recipient = angular.extend({},vm.templateBulkItem.recipient);//default recipient
                recipient.deliveryMethodType = bulkRecipient.deliveryMethod_type_name;
                recipient.showValue = bulkRecipient.showValue;

                //override the recipient if the delivery method is 'email' due to post using the values template vm.templateBulkItem.recipient
                if (recipient.deliveryMethodType === 'email') {
                    recipient.firstName = bulkRecipient.recipient_name_first;
                    recipient.lastName = bulkRecipient.recipient_name_last;
                }
                recipient.email = bulkRecipient.recipient_email;
                if (recipient.country && recipient.country.countryName) {
                    recipient.country = recipient.country.countryName;
                }
                recipient.message = bulkRecipient.message;
                recipient.comment = bulkRecipient.comment;
                recipient.emailCategoryId = vm.templateBulkItem.itemEmailCategoryId;

                var value = bulkRecipient.value;
                if (typeof value === 'string') {
                    value = parseNumber(bulkRecipient.value);
                }
                recipient.itemTotalPrice = value;
                console.log('recipient', recipient);
                bulkVM.recipients.push(recipient);

            });

            //params needed to create shopping cart from scratch
            //build shopping cart locale
            var variant = '';
            if ($transition$.params().contextType) {
                variant = '_' + $transition$.params().contextType;
            }
            variant += (variant.length === 0 ? '_' : '') + currentProperty.hotelId;

            var src = undefined;
            if (typeof currentPropertyGroup !== 'undefined') {
                src = currentPropertyGroup.code;
            }
            if ($transition$.params().src) {
                src = $transition$.params().src;
            }
            bulkVM.ref = $transition$.params().ref;
            bulkVM.src = src;
            bulkVM.lang = currentLanguage + variant;
            bulkVM.contextType = $transition$.params().contextType;
            bulkVM.propertyType = $transition$.params().propertyType;
            bulkVM.propertyCode = $transition$.params().propertyId;
            bulkVM.customerRef = $transition$.params().customerRef;

            return ShoppingCartAddBulkRecipientsAndItem.add(bulkVM,
                function (response) {
                    try {
                        vm.trackCheckout(bulkVM.item,bulkVM.recipients.size);
                    } catch (e) {

                    }
                    return cb(response);
                },
                function (err) {
                    return ecb(err);
                }.bind(this)).$promise;
        }


        /**
         * we are using handsontable version 0.38.1 CE, which corresponds to Pro version 1.18.1 (Released on 20th of March, 2018)
         * Documentation: https://handsontable.com/docs/1.18.1/Options.html
        **/
        vm.hot = {};

        vm.hot.items = [];

        vm.hot.loadData = function(flatData){
            console.log('loading data',flatData);
            vm.hot.items = [];
            if(flatData && flatData.length>0){

                angular.forEach(flatData,function(item,key){
                    if(item.itemId ==vm.templateBulkItem.itemId){
                        var modelledItem = vm.hot.model(item);
                        vm.hot.items.push(modelledItem);
                    }
                });
            }

            //add default item if nothing loaded from storage
            if(vm.hot.items.length==0){
                for(var i = 0; i<1;i++){
                    var item = angular.extend({},vm.hot.flatItemDataModel);
                    var modelledItem = vm.hot.model(item);
                    vm.hot.items.push(modelledItem);
                }
            }

            vm.templateBulkItem.itemQuantity = Math.max(1,vm.hot.items.length);
        }
        vm.hot.flatItemDataModel = {
            "recipient_name_first" : "",
            "recipient_name_last" : "",
            "recipient_email" : "",
            "message" : "",
            "comment" : "",
            "price": vm.templateBulkItem.itemTotalPrice,
            "value": vm.templateBulkItem.itemTotalPriceBeforeDiscount,
                "deliveryMethod_type_name": vm.templateBulkItem.recipient.deliveryMethodType,
            "showValue": vm.templateBulkItem.recipient.showValue,
            "itemId" : vm.templateBulkItem.itemId
        };

        vm.hot.model = function(opts) {
            var
                _pub = {},
                _priv = angular.extend({},vm.hot.flatItemDataModel);

            for (var i in opts) {
                if (opts.hasOwnProperty(i)) {
                    _priv[i] = opts[i];
                }
            }

            _pub.attr = function (attr, val) {
                if (typeof val === 'undefined') {
                    //window.console && console.log("\t\tGET the", attr, "value of", _pub);
                    return _priv[attr];
                }
                //window.console && console.log("SET the", attr, "value of", _pub);
                _priv[attr] = val;

                return _pub;
            };

            _pub.internalData = _priv;

            return _pub;
        }

        vm.hot.modelProperty = function(attr){
            return function (row, value) {
                if(typeof row==='undefined'){
                    return undefined;
                }
                if(row==null || row.attr ==null){
                    return undefined;
                }
                //console.log("GET the", attr, "value of row data", row);
                return row.attr(attr, value);
            }
        }

        var storageFlatData = $localStorage.bulkFlatData || {};
        var flatDataInstance = storageFlatData[vm.shoppingCart.shoppingCartKey] || [];
        vm.hot.loadData(flatDataInstance);

        vm.hot.beforePaste = function(data,coords){
            //console.log('beforePaste',data);
            if(vm.hot.settings.maxRows){
                if(data.length>vm.hot.settings.maxRows){
                    data.length = vm.hot.settings.maxRows;
                }
            }
            if(data.length){
                for (var i = 0; i < data.length; i++) {
                    for (var j = 0; j < data[i].length; j++) {
                        data[i][j] = data[i][j].trim();
                    }
                }
            }
            vm.templateBulkItem.itemQuantity = Math.max(1,vm.hot.items.length,coords[0].startRow+data.length);
        }

        vm.hot.afterPaste = function(){
            //after pasing values from excel we must format all prices to numbers
            updatePriceAndSave();
        }

        vm.hot.beforeCreateRow = function(createRowAtIndex,amountOfRows,source){
            /*if(source=="ContextMenu.rowBelow"){
                //createRowAtIndex
                createRowsInItems(createRowAtIndex, amountOfRows, false);
            }
            if(source=="ContextMenu.rowAbove"){
                createRowsInItems(createRowAtIndex, amountOfRows, true);
            }
            */;
        }

        vm.hot.afterChange = function(changes, source){
            //save only if changes is not null
            if (changes === null) {
                return;
            }
            updatePriceAndSave();

            // var hotInstanceRegisterer = $injector.get('hotRegisterer');
            // var hotInstance = vm.hot.instance || angular.element("#"+vm.shoppingCart.shoppingCartKey).handsontable('getInstance') || hotInstanceRegisterer.getInstance(vm.shoppingCart.shoppingCartKey);
        }

        vm.hot.afterRemoveRow = function(index, amount, physicalRows, source){
            vm.templateBulkItem.itemQuantity = Math.max(1,vm.hot.items.length);
        }



        vm.hot.afterInit = function(){
            vm.hot.instance = this;
            //vm.hot.instance.addHook('beforePaste', vm.hot.beforePaste);//this work but is now moved to settings object
            //vm.hot.instance.addHook('afterPaste', vm.hot.afterPaste);//this work but is now moved to settings object
        }

        //https://emailregex.com/
        vm.hot.emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            ///(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

        vm.hot.messageValidator = function(value, callback) {
            //if null add empty
            if (value == null) {
                value = '';
            }

            //if allowEmpty and empty return true
            if (this.allowEmpty && value === '') {
                callback(true);
            }

            if (value.length > vm.shoppingCartConfigs[vm.currentProperty.hotelId].configMap.dedicationMessageLengthPost) {
                console.log('messageValidator', value.length, vm.shoppingCartConfigs[vm.currentProperty.hotelId].configMap.dedicationMessageLengthPost);
                callback(false);
            } else {
                callback(true);
            }
        }

        vm.hot.requiredValidator = function(value, callback) {
            if (value == null) {
                value = '';
            }
            if (this.allowEmpty && vm.templateBulkItem.recipient.deliveryMethodType === "post" && value === '') {
                callback(true);
            }
            // Check if the delivery method is 'email'
            else if (vm.templateBulkItem.recipient.deliveryMethodType === 'email') {
                // Apply the regex validation for email
                var isValidEmail = /^(?!\s*$).+/.test(value);
                callback(isValidEmail);
            }
            // If the delivery method is 'post'
            else if (vm.templateBulkItem.recipient.deliveryMethodType === 'post') {
                // Check for non-empty string
                var isValidPost = value !== '';
                callback(isValidPost);
            }
            else {
                // Default case if deliveryMethodType is neither 'email' nor 'post'
                callback(true);
            }
        };

        vm.hot.colHeadersFn = function() {
            var totals = getItemTotals();
            vm.totalValue = totals.totalValue;//just in case
            vm.totalPrice = totals.totalPrice;

            //var cacheSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
            //$locale.NUMBER_FORMATS.DECIMAL_SEP = decimalSeparator;

            var colHeaders = [];
            colHeaders.push('Value<br/><small>'+$translate.instant("shopping-cart.items.total")+': '+$filter('number')(totals.totalValue,2)+'</small>');
            if (vm.templateBulkItem.recipient.deliveryMethodType === 'email') {
                colHeaders.push($translate.instant("vouchers.form.label.recipient.firstname"));
                colHeaders.push($translate.instant("vouchers.form.label.recipient.lastname"));
                colHeaders.push($translate.instant("vouchers.form.label.recipient.email")+'<br/><small>('+$translate.instant("vouchers.form.label.recipient.bulk.deliverhere")+')</small>');
            }

            if ($transition$.params().contextType !== 'refunds') {
                colHeaders.splice(0, 0, '<span class="w3-text-gray">'+$translate.instant("vouchers.form.label.price")+'<br/><small>'+$translate.instant("shopping-cart.items.total")+': '+totalPrice+'</small></span>');
            }

            //$locale.NUMBER_FORMATS.DECIMAL_SEP = cacheSeparator;

            if(vm.useComment){
                colHeaders.push($translate.instant("vouchers.form.label.comment"));
            }
            else {
                colHeaders.push($translate.instant("vouchers.form.label.dedication"));
            };


            var hotInstanceRegisterer = undefined;
            try { hotInstanceRegisterer = $injector.get('hotRegisterer'); } catch (e){}
            var hotInstance = hotInstanceRegisterer ? hotInstanceRegisterer.getInstance(vm.shoppingCart.shoppingCartKey) : undefined;
            if(hotInstance){
                hotInstance.updateSettings({
                    colHeaders : colHeaders
                });
            }
            return colHeaders;
        }

        vm.hot.inRangeValidator = function(value, callback) {

            if (testNumber(value)) {
                var valueAsNumber = parseNumber(value);
                if (vm.currentVoucher.currency === 'ZAR' && !Number.isInteger(valueAsNumber)) {
                    callback(false);
                } else if (vm.voucherPool.enableTypedAmounts && valueAsNumber >= vm.voucherPool.minValue && valueAsNumber <= vm.voucherPool.maxValue) {
                    callback(true);
                } else if (vm.valueRange.includes(valueAsNumber)) {
                    callback(true);
                } else {
                    callback(false);
                }
            } else {
                callback(false);
            }
        };

        function getItemTotals() {
            var totalPrice = 0;
            var totalValue = 0;
            angular.forEach(vm.hot.items, function (item, index) {

                var price = item.internalData.price;
                if (typeof price === 'string') {
                    price = parseNumber(item.internalData.price);
                }
                var value = item.internalData.value;
                if (typeof value === 'string') {
                    value = parseNumber(item.internalData.value);
                }
                totalPrice += price;
                totalValue += value;
            });
            return {totalPrice, totalValue};
        }

        vm.getColumns = function() {
            var totals = getItemTotals();
            var columns = [];
            if ($transition$.params().contextType !== 'refunds') {
                columns.push({
                    data: vm.hot.modelProperty('price'),
                    title: '<span class="w3-text-gray">' + $translate.instant("vouchers.form.label.price") + '<br/><small>' + $translate.instant("shopping-cart.items.total") + ': ' + totals.totalPrice + '</small></span>',
                    type: 'numeric',
                    width: 80,
                    numericFormat: {
                        pattern: '0,0.00'
                    },
                    validator: 'numeric',
                    readOnly: true //PRICE is caluclated based on VALUE (it's promo code aware)
                });
            }
            columns.push({
                data: vm.hot.modelProperty('value'),
                width: 100,
                title: 'Value<br/><small>'+$translate.instant("shopping-cart.items.total")+': '+$filter('number')(totals.totalValue,2)+'</small>',
                source: vm.valueRange,
                type: vm.voucherPool.enableTypedAmounts ? 'numeric' : 'dropdown',
                // the numeric pattern decimal separator is working in 'dropdown' (showing , on selected langs) but not 'numeric' (always showing .)
                numericFormat: {
                    pattern: vm.currentVoucher.currency === 'ZAR' ? '0,000' :  '0,0.00'
                },
                selectOptions: [225, 450],
                validator: vm.hot.inRangeValidator
            });

            if (vm.templateBulkItem.recipient.deliveryMethodType === 'email') {
                columns.push({
                    data: vm.hot.modelProperty('recipient_name_first'),
                    title: $translate.instant("vouchers.form.label.recipient.firstname"),
                    type: 'text',
                    width: 250,
                    validator: vm.hot.requiredValidator,
                    allowInvalid: true,
                    allowEmpty: true
                });

                columns.push({
                    data: vm.hot.modelProperty('recipient_name_last'),
                    title: $translate.instant("vouchers.form.label.recipient.lastname"),
                    type: 'text',
                    width: 250,
                    validator: vm.hot.requiredValidator,
                    allowEmpty: true,
                    allowInvalid: true
                });

                columns.push({
                    data: vm.hot.modelProperty('recipient_email'),
                    title: $translate.instant("vouchers.form.label.recipient.email")+'<br/><small>('+$translate.instant("vouchers.form.label.recipient.bulk.deliverhere")+')</small>',
                    type: 'text',
                    width: 250,
                    validator: vm.hot.emailValidator,
                    allowEmpty: true,
                    allowInvalid: true
                });
            }

            var characterLimitText = '';
            if (vm.templateBulkItem.recipient.deliveryMethodType === 'post' && vm.shoppingCartConfigs[vm.currentProperty.hotelId].configMap.dedicationMessageLengthPost) {
                characterLimitText = ' ('+vm.shoppingCartConfigs[vm.currentProperty.hotelId].configMap.dedicationMessageLengthPost+' characters)';
            }
            columns.push({
                data: vm.useComment ? vm.hot.modelProperty('comment') : vm.hot.modelProperty('message'),
                type: 'text',
                width: undefined,
                validator: vm.hot.messageValidator,
                allowEmpty: true,
                title: $translate.instant("vouchers.form.label.dedication") + characterLimitText
            });

            return columns;

        }

        vm.hot.settings = {
            id: vm.shoppingCart.shoppingCartKey,
            contextMenu: ['row_above', 'row_below', 'remove_row', 'undo', 'redo'],
            persistentState: true,
            copyPaste: true,
            //dataSchema: vm.hot.model,//this is not working
            /* dataSchema is defined in html angular component 'dataschema' attribute and points to "vm.hot.model" */
            beforeCreateRow: vm.hot.beforeCreateRow,
            beforePaste: vm.hot.beforePaste, //important this will create required empty rows before paste
            afterPaste: vm.hot.afterPaste,
            afterChange: vm.hot.afterChange,
            afterRemoveRow: vm.hot.afterRemoveRow,
            afterInit: vm.hot.afterInit, //not working not sure why
            stretchH: 'last',
            minSpareRows: 0,
            fillHandle: {
                direction: 'vertical',
                autoInsertRow: false //prevent fill handle from creating extra row at the bottom https://handsontable.com/docs/7.1.0/demo-auto-fill.html
            },
            rowHeaders: true,
            maxRows: vm.maxBulkRows,
            minRows: 1,//Pat don't want to be able to see 0 rows
            undo: true,
            trimWhitespace: true,
            //data : vm.hot.items,//this is not working,
            /* data is defined in html angular component 'datarows' attribute and points to "vm.hot.items"
            data: [
                model({id: 1, name: 'Ted Right', address: ''}),
                model({id: 2, name: 'Frank Honest', address: ''}),
                model({id: 3, name: 'Joan Well', address: ''}),
                model({id: 4, name: 'Gail Polite', address: ''}),
                model({id: 5, name: 'Michael Fair', address: ''})
            ],
            */
            // colHeaders: vm.hot.colHeadersFn(),
            // colWidths: [
            //     //80, //Delivery
            //     //80, //Show Value
            //     80, //Price
            //     100, //Value
            //     250,//First Name
            //     250,//Last Name
            //     250,//Comment or Message
            //     undefined],
            columns: vm.getColumns(),
        };


        //don't display the extra 'price' column for refunds.. maybe change this to those with discounts only.
        // if ($transition$.params().contextType === 'refunds') {
        //     vm.hot.settings.columns.shift(1);
        //     vm.hot.settings.colWidths.shift(1);
        // }

        //watch changes in global values

        $scope.$watch('vm.templateBulkItem.itemQuantity', function(val1,val2) {
            console.info("quantity changed from to",val2,val1);
            bulkRecipientsUpdateQuantity(val1);
        });

        $scope.$watch('vm.templateBulkItem.recipient.showValue', function(val1,val2) {
            console.info("showValue changed from to",val2,val1);
            bulkRecipientsUpdateShowValue(val1);
        });

        $scope.$watch('vm.templateBulkItem.recipient.deliveryMethodType', function(val1,val2) {
            console.info("deliveryMethodType changed from to", val2, val1);
            //change each bulkInternal item deliveryMethod_type_name to match the new value
            angular.forEach(vm.hot.items,function(item,key){
                item.internalData.deliveryMethod_type_name = val1;
            });
            if (val1 !== val2) {
                console.log('reload...')

                var hotInstanceRegisterer = undefined;
                try {
                    hotInstanceRegisterer = $injector.get('hotRegisterer');
                } catch (e) {
                }
                var hotInstance = hotInstanceRegisterer ? hotInstanceRegisterer.getInstance(vm.shoppingCart.shoppingCartKey) : undefined;
                if (hotInstance) {
                    hotInstance.updateSettings({columns: vm.getColumns()});
                }
            }
        });

        $scope.$watch('vm.shoppingCart.shoppingCartDiscounts', function(val1,val2) {
            console.info("shoppingCartDiscounts changed from to",val2,val1);
            var applicablePromo = PromotionCodeService.findApplicablePromo(vm.shoppingCart, vm.currentProperty, typeof vm.currentPropertyGroup !== 'undefined', vm.currentVoucher)
            console.log("new promo",applicablePromo );
            vm.appliedPromotionCode = applicablePromo;
            //1. change bulk item total price
            vm.templateBulkItem.itemTotalPrice = ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount(vm.currentVoucher.totalRate.priceMin, vm.appliedPromotionCode, vm.templateBulkItem.itemQuantity);//it will be recalculated serverside
            //2. update all items & save
            updatePriceAndSave();

        });


        function updatePriceAndSave(saveOnly){
            console.log('updatePriceAndSave', saveOnly)
            var flatData = {};
            flatData[vm.shoppingCart.shoppingCartKey] = [];

            angular.forEach(vm.hot.items,function(item,index) {
                var newPrice = item.internalData.value;
                if (typeof newPrice === 'string') {
                    var newPrice = parseNumber(item.internalData.value);
                }
                if (!saveOnly){
                    //of change was done in voucher value
                    var discountAllowed = true;
                    if(vm.appliedPromotionCode && vm.appliedPromotionCode.maxUsage>0 && vm.appliedPromotionCode.used+index>=vm.appliedPromotionCode.maxUsage){
                        discountAllowed = false;
                    }
                    if(discountAllowed){
                        item.internalData.price = ShoppingCartItemDetailsService.calculateSelectedAmountAfterDiscount(newPrice, vm.appliedPromotionCode, vm.templateBulkItem.itemQuantity);
                    } else {
                        item.internalData.price = newPrice;
                    }
                    item.internalData.deliveryMethod_type_name = vm.templateBulkItem.recipient.deliveryMethodType;
                }
                flatData[vm.shoppingCart.shoppingCartKey].push(item.internalData);
            });
            //vm.hot.settings.colHeaders = vm.hot.colHeadersFn();
            //vm.hot.settings.columns = vm.getColumns();
            $localStorage.bulkFlatData = flatData;

            var hotInstanceRegisterer = undefined;
            try {
                hotInstanceRegisterer = $injector.get('hotRegisterer');
            } catch (e) {
            }
            var hotInstance = hotInstanceRegisterer ? hotInstanceRegisterer.getInstance(vm.shoppingCart.shoppingCartKey) : undefined;
            if (hotInstance) {
                hotInstance.updateSettings({columns: vm.getColumns()});
                hotInstance.validateCells();
            }
        }

        function createRowsInItems(createRowAtIndex, amountOfRows, isAbove){
            //createRowAtIndex - this index might not exist yet
            for(var n=0;n<amountOfRows;n++){
                var item = angular.extend({},vm.hot.flatItemDataModel);
                var newModelledItem = vm.hot.model(item);
                newModelledItem.internalData.deliveryMethod_type_name = vm.templateBulkItem.recipient.deliveryMethodType;
                vm.hot.items.splice(createRowAtIndex,0,newModelledItem);
            }
        }

        function bulkRecipientsUpdateQuantity(newQuantity){
            var size = vm.hot.items.length;

            //add items
            if(newQuantity>size){
                var numberOfNewItems = newQuantity - size;
                console.log("adding "+numberOfNewItems+" new items");
                createRowsInItems(vm.hot.items.length, numberOfNewItems, false);

            }
            else if(newQuantity<size){
                //remove items
                vm.hot.items.splice(newQuantity,size);
            }
            updatePriceAndSave();
        }

        function bulkRecipientsUpdateShowValue(newShowValue){
            angular.forEach(vm.hot.items,function(item,key){
                item.internalData.showValue = newShowValue;
            });
            updatePriceAndSave(true);
        }

        vm.trackCheckout = function(item, quantity){

            Analytics.addProduct(
                item.itemId,//productId
                item.itemName,//name
                item.itemType,//category
                item.owningProperty.propertyCode,//brand
                '',//variant
                parseNumber(item.itemTotalPrice),//price
                quantity,//quantity
                '',//coupon
                ''//position
            );
            Analytics.trackCheckout(1, 'Details');
        }


        function goToPayment(event){
            console.log('goToPayment')
            event.preventDefault();
            vm.bulkAddFailed = false;

            var validateForm = $q.defer();
            if(vm.checkoutForm.$invalid){
                console.log('not valid...', vm.checkoutForm)
                validateForm.reject('false');
            }
            else {
                console.log('valid...')
                validateForm.resolve('true');
            }

            var validateHandsontable = $q.defer();
            this.hot.instance.validateCells(function(valid) {
                console.log('validateCells',valid);
                if (valid) {
                    validateHandsontable.resolve('true');
                }
                else {
                    validateHandsontable.reject('false');
                }
            });

            var validatationPromise = $q.all([validateForm.promise, validateHandsontable.promise])
                .then(function (succ) {
                    vm.checkoutFormInvalid = false;
                    addBulkRecipientsAndItem(goToPaymentState, refreshAndRevalidate);
                }, function (err) {
                    console.log('add bulk error', err)
                    vm.checkoutFormInvalid = true;
                });
        }

        /**
         * This is the successful callback after adding item and recipients
         */
        function goToPaymentState(){
            console.log('goToPaymentState')
            $state.transitionTo("payment", $transition$.params(), {
                reload: 'site', inherit: true, notify: true
            });
        }

        /**
         * This is the error callback after adding item and recipients
         */
        function refreshAndRevalidate(err){
            console.log('refreshAndRevalidate')
            $transition$.params().revalidate = true;
            //$state.transitionTo($state.current, $stateParams, {
            //    reload: 'site', inherit: false, notify: true
            //});
            vm.bulkAddFailed = true;
        };


        function browseMore(event) {
            event.preventDefault();
            var shopMoreState = DetermineShopMoreState.getShopMoreState();
            console.log('shopMoreState', shopMoreState)
            $state.go(shopMoreState.name, shopMoreState.params);
        }

        function range(min, max, step) {
            //console.log(min);
            //console.log(max);
            //console.log(step);

            step = step || 1;
            if (step == 0) {
                step = 1;
            }
            if (max == 0) {
                max = min;
            }

            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(parseNumber(i));
            }

            return input;
        }

        function normalizeNumber(input){
            if(isNaN(input)===false){
                return input.toString();
            }
            var value = input.toString();
            var cleanPattern = new RegExp('[^-+0-9$'+decimalSeparator+']', 'g');
            var cleaned = value.replace(cleanPattern, '');
            var normalized = cleaned.replace(decimalSeparator, '.');
            return normalized;
        }


        function parseNumber(input) {
            return parseFloat(normalizeNumber(input));
        }

        function testNumber(input) {
            return normalizeNumber(input).match(/^[0-9.]*$/);
        }


    }
})();
