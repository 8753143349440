(function() {
    'use strict';

    angular
        .module('vobeApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('confirmation', {
            parent: 'property',
            url: '/confirmation/:uuid?walletDebug',
            data: {
                authorities: [],
                autoscroll : true,
                pageTitle : 'confirmation.page.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/site/confirmation/confirmation.html',
                    controller: 'ConfirmationController',
                    controllerAs: 'vm'
                },
                'header@': {
                    templateUrl: 'app/layouts/header/header.html',
                    controller: 'BrandedHeaderController',
                    controllerAs: 'vm'
                },
                'footer@': {
                    templateUrl: 'app/layouts/footer/footer.html',
                    controller: 'BrandedFooterController',
                    controllerAs: 'vm'
                },
                'cookies@': {
                    templateUrl: 'app/components/cookies-consent/cookies-banner.html',
                    controller: 'CookiesBannerController',
                    controllerAs: 'vm',
                }
            },
            resolve: {
                mainTranslatePartialLoader: ['$stateParams', '$translate', '$translatePartialLoader','currentProperty', function ($stateParams, $translate,$translatePartialLoader,currentProperty) {
                	var variant = "";
                	if($stateParams.contextType){
                		variant = "_"+$stateParams.contextType;
                	}
                	variant += "_"+currentProperty.hotelId;

                	$translatePartialLoader.addPart('confirmation'+variant);
                    $translatePartialLoader.addPart('vouchers'+variant);
                    return $translate.refresh();
                }],
                voucherConfig: function (VobeConfig, currentProperty) {
                    'ngInject';
                    //gather property ids
                    var propertyIds = [];
                    propertyIds.push(currentProperty.hotelId);
                    return VobeConfig.config.get({propertyIds: propertyIds}).$promise;
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'confirmation',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
                confirmation: function($stateParams, Confirmation) {
                    'ngInject';
                    return Confirmation.get({ uuid: $stateParams.uuid, ref: '' }).$promise;
                },
                brandingTemplateUUID: function(brandingTemplateUUID) {
                    'ngInject';
                    return brandingTemplateUUID;
                },
            }
        })

        ;
    }
})();
